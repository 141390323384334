import { FC } from 'react';
import styled from 'styled-components';
import BgSrc from '../assets/img/SignUpBg.png';
import Button from './elements/Button';
import { theme } from '../styles/theme';
import { goToReg } from '../consts';
import { useTranslation } from 'react-i18next';

const SignUp: FC = () => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<Title>{t('landing.signUpBlock.title')}</Title>
			<Subheader>
				<span>{t('landing.signUpBlock.subHeaderPart1')}</span> {t('landing.signUpBlock.subHeaderPart2')}
			</Subheader>
			<Button
				mainWidth={350}
				text={t('landing.signUpBlock.button')}
				onClick={goToReg}
			/>
		</Wrapper>
	);
};

const Subheader = styled.div`
	text-align: center;
	color: #7b889d;
	line-height: 1.6;
	font-size: 14px;
	font-family: ${theme.fonts.mainBold};
	margin-bottom: 45px;

	span {
		color: #6385c7;
	}
`;

const Title = styled.h2`
	color: #ffffff;
	text-align: center;
	font-size: 36px;
	font-family: ${theme.fonts.mainBold};
	line-height: 44px;
	margin-bottom: 22px;
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 163px 0 178px;
	background: url(${BgSrc}) #1b1a21 no-repeat;

	@media (max-width: 400px) {
		padding: 160px 20px 180px;

		.button {
			width: 100%;
		}
	}
`;

export default SignUp;