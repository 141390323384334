import { FC } from 'react';
import styled from 'styled-components';
import { theme } from '../styles/theme';
import Button from './elements/Button';
import src from '../assets/img/Notebook.png';
import OGImgSrc from '../assets/img/Analyze.jpg';
import EllipseBlur from '../assets/img/EllipseBlur';
import { goToReg } from '../consts';
import { useTranslation } from 'react-i18next';

const MainBlock: FC = () => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<EllipseBlurWrapper>
				<EllipseBlur />
			</EllipseBlurWrapper>
			<Content>
				<BgPolygon />
				<img
					src={src}
					alt=''
					className={'notebook-img'}
				/>
				<TextWrapper>
					<Title>
						{t('landing.mainBlock.titlePart1')} <span>{t('landing.mainBlock.titlePart2')}</span>{' '}
						{t('landing.mainBlock.titlePart3')}
					</Title>
					<p>{t('landing.mainBlock.txt')}</p>
					<Button
						text={t('landing.mainBlock.signUp')}
						mainWidth={200}
						onClick={goToReg}
					/>
				</TextWrapper>
			</Content>
			<OGImg>
				<img
					src={OGImgSrc}
					alt=''
				/>
			</OGImg>
		</Wrapper>
	);
};

const OGImg = styled.div`
	position: absolute;
	z-index: -1;
	pointer-events: none;
	opacity: 0;
	visibility: hidden;
`;

const Wrapper = styled.div`
	overflow: hidden;
	width: 100%;
	position: relative;
	margin: -55px 0 100px;
	padding-bottom: 230px;

	@media (max-width: 790px) {
		padding: 0 20px 150px;
		margin: -55px 0 0;
	}

	.notebook-img {
		position: absolute;
		top: 0;
		left: 410px;
		z-index: 11;

		@media (max-width: 1300px) {
			width: 860px;
			height: auto;
			left: 330px;
		}

		@media (max-width: 700px) {
			position: relative;
			left: -60px;
			margin-bottom: 64px;
		}

		@media (max-width: 376px) {
			width: 597px;
		}
	}
`;

const Content = styled.div`
	max-width: 1136px;
	height: 609px;
	margin: 222px auto 0;
	position: relative;
	display: flex;
	align-items: center;

	@media (max-width: 1300px) {
		max-width: 680px;
		height: 494px;
	}

	@media (max-width: 700px) {
		flex-direction: column;
		align-items: flex-start;
		margin: 87px auto 0;
		height: auto;
	}
`;

const TextWrapper = styled.div`
	position: relative;
	z-index: 11;

	@media (max-width: 376px) {
		width: 100%;
		.button {
			width: 100%;
		}
	}

	p {
		max-width: 350px;
		line-height: 1.5;
		font-size: 18px;
		font-family: ${theme.fonts.mainMedium};
		color: #7b889d;
		margin-bottom: 68px;

		@media (max-width: 376px) {
			font-size: 14px;
			margin-bottom: 15px;
			max-width: 220px;
			line-height: 21px;
		}
	}
`;

const Title = styled.h1`
	color: #ffffff;
	max-width: 328px;
	font-size: 48px;
	font-family: ${theme.fonts.mainBold};
	line-height: 58px;
	margin-bottom: 10px;
	position: relative;

	@media (max-width: 376px) {
		font-size: 36px;
		line-height: 43px;
		margin-bottom: 15px;
		max-width: 246px;
	}

	&::before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 11px;
		height: 9px;
		width: 104px;
		border-radius: 18px;
		background: rgba(99, 133, 199, 0.3);

		@media (max-width: 376px) {
			width: 79px;
			bottom: 9px;
		}
	}

	span {
		color: ${theme.colors.primary};
	}
`;

const EllipseBlurWrapper = styled.div`
	position: absolute;
	z-index: 10;

	@media (max-width: 700px) {
		display: none;
	}
`;

const BgPolygon = styled.div`
	position: absolute;
	width: 700px;
	height: 700px;
	border: 70px solid ${theme.colors.primary};
	border-radius: 33px;
	top: -50px;
	left: 530px;
	transform: rotate(65deg);

	@media (max-width: 1300px) {
		width: 575px;
		height: 575px;
		border: 60px solid ${theme.colors.primary};
		left: 430px;
	}

	@media (max-width: 700px) {
		width: 500px;
		height: 500px;
		top: 0;
		left: 20px;
	}

	@media (max-width: 376px) {
		width: 400px;
		height: 400px;
		border: 40px solid ${theme.colors.primary};
		top: -30px;
	}
`;

export default MainBlock;
