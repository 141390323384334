import { FC } from 'react';
import styled from 'styled-components';
import { theme } from '../styles/theme';
import CubekitLogo from '../assets/icons/CubekitLogo';
import { goToAuth, goToReg } from '../consts';
import { useTranslation } from 'react-i18next';
import LangEnIcon from '../icons/LangEnIcon';
import LangRuIcon from '../icons/LangRuIcon';
import { GeneralStore } from '../stores/general';

const Header: FC = () => {
	const { t, i18n } = useTranslation();

	return (
		<HeaderWrapper>
			<CubekitLogo />
			<ActionsWrapper>
				<LanguagesWrapper>
					<LanguageItem
						isActive={i18n.language === 'ru'}
						onClick={() => GeneralStore.changeLanguage('ru', i18n)}>
						<LangRuIcon />
					</LanguageItem>
					<LanguageItem isActive={i18n.language === 'en'}
						onClick={() => GeneralStore.changeLanguage('en', i18n)}>
						<LangEnIcon />
					</LanguageItem>
				</LanguagesWrapper>
				<Action onClick={goToAuth}>{t('landing.header.signIn')}</Action>
				<Action onClick={goToReg}>{t('landing.header.signUp')}</Action>
			</ActionsWrapper>
		</HeaderWrapper>
	);
};

const LanguagesWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const LanguageItem = styled.div<{ isActive: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px 4px;
	border-radius: 6px;
	cursor: pointer;
	border: 1px solid ${({ isActive }) => (isActive ? 'rgb(123, 136, 157)' : 'transparent')};
`;

const HeaderWrapper = styled.header`
	max-width: 1520px;
	margin: 10px auto 0;
	height: 45px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 100;

	@media (max-width: 1600px) {
		padding: 0 40px;
	}

	@media (max-width: 450px) {
		padding: 0 20px;
		svg {
			width: 170px;
		}
	}
`;

const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 60px;

	@media (max-width: 1000px) {
		gap: 35px;
	}
`;

const Action = styled.div`
	cursor: pointer;
	color: #ffffff;
	font-size: 14px;
	font-family: ${theme.fonts.mainRegular};
`;

export default Header;
