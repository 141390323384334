import { FC } from 'react';

const LangEnIcon: FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'>
			<mask
				id='mask0_119_869'
				style={{ maskType: 'alpha' }}
				maskUnits='userSpaceOnUse'
				x='0'
				y='3'
				width='18'
				height='12'>
				<path
					d='M16.2857 3H1.71429C0.767512 3 0 3.71634 0 4.6V13.4C0 14.2837 0.767512 15 1.71429 15H16.2857C17.2325 15 18 14.2837 18 13.4V4.6C18 3.71634 17.2325 3 16.2857 3Z'
					fill='white'
				/>
			</mask>
			<g mask='url(#mask0_119_869)'>
				<path
					d='M16.2857 3H1.71429C0.767512 3 0 3.71634 0 4.6V13.4C0 14.2837 0.767512 15 1.71429 15H16.2857C17.2325 15 18 14.2837 18 13.4V4.6C18 3.71634 17.2325 3 16.2857 3Z'
					fill='white'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 3H7.71429V8.6H0V3Z'
					fill='#444379'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0.857178 3.80005V4.60005H1.71432V3.80005H0.857178ZM2.57146 3.80005V4.60005H3.42861V3.80005H2.57146ZM4.28575 3.80005V4.60005H5.14289V3.80005H4.28575ZM6.00003 3.80005V4.60005H6.85718V3.80005H6.00003ZM5.14289 4.60005V5.40005H6.00003V4.60005H5.14289ZM3.42861 4.60005V5.40005H4.28575V4.60005H3.42861ZM1.71432 4.60005V5.40005H2.57146V4.60005H1.71432ZM0.857178 5.40005V6.20005H1.71432V5.40005H0.857178ZM2.57146 5.40005V6.20005H3.42861V5.40005H2.57146ZM4.28575 5.40005V6.20005H5.14289V5.40005H4.28575ZM6.00003 5.40005V6.20005H6.85718V5.40005H6.00003ZM0.857178 7.00005V7.80005H1.71432V7.00005H0.857178ZM2.57146 7.00005V7.80005H3.42861V7.00005H2.57146ZM4.28575 7.00005V7.80005H5.14289V7.00005H4.28575ZM6.00003 7.00005V7.80005H6.85718V7.00005H6.00003ZM5.14289 6.20005V7.00005H6.00003V6.20005H5.14289ZM3.42861 6.20005V7.00005H4.28575V6.20005H3.42861ZM1.71432 6.20005V7.00005H2.57146V6.20005H1.71432Z'
					fill='#A7B6E7'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M7.71429 3V3.8H18V3H7.71429ZM7.71429 4.6V5.4H18V4.6H7.71429ZM7.71429 6.2V7H18V6.2H7.71429ZM7.71429 7.8V8.6H18V7.8H7.71429ZM0 9.4V10.2H18V9.4H0ZM0 11V11.8H18V11H0ZM0 12.6V13.4H18V12.6H0ZM0 14.2V15H18V14.2H0Z'
					fill='#ED4C49'
				/>
				<path
					d='M16.2856 3.40002H1.71418C1.0041 3.40002 0.428467 3.93728 0.428467 4.60002V13.4C0.428467 14.0628 1.0041 14.6 1.71418 14.6H16.2856C16.9957 14.6 17.5713 14.0628 17.5713 13.4V4.60002C17.5713 3.93728 16.9957 3.40002 16.2856 3.40002Z'
					stroke='black'
					strokeOpacity='0.1'
				/>
			</g>
		</svg>
	);
};

export default LangEnIcon;
