import ContentExtraction from '../../assets/icons/ContentExtraction';
import Ai from '../../assets/icons/Ai';
import Customization from '../../assets/icons/Customization';
import VisualPresentation from '../../assets/icons/VisualPresentation';
import Automation from '../../assets/icons/Automation';
import Api from '../../assets/icons/Api';

export const getData = (t: any) => [
	{
		icon: <ContentExtraction />,
		title: t('landing.features.list.item1.title'),
		text: (
			<p>
				{t('landing.features.list.item1.txtPart1')}
				<span> {t('landing.features.list.item1.txtPart2')}</span>,
				<span> {t('landing.features.list.item1.txtPart3')}</span>,
				<span> {t('landing.features.list.item1.txtPart4')}</span>,
				<span> {t('landing.features.list.item1.txtPart5')}</span>,
				<span> {t('landing.features.list.item1.txtPart6')} </span>
				{t('landing.features.list.item1.txtPart7')}
			</p>
		),
	},
	{
		icon: <Ai />,
		title: t('landing.features.list.item2.title'),
		text: (
			<p>
				{t('landing.features.list.item2.txtPart1')}
				<span> {t('landing.features.list.item2.txtPart2')}</span>,
				<span> {t('landing.features.list.item2.txtPart3')}</span>,
				<span> {t('landing.features.list.item2.txtPart4')}</span>
				{t('landing.features.list.item2.txtPart5')}
			</p>
		),
	},
	{
		icon: <Customization />,
		title: t('landing.features.list.item3.title'),
		text: (
			<p>
				{t('landing.features.list.item3.txtPart1')}
				<span> {t('landing.features.list.item3.txtPart2')} </span>
				{t('landing.features.list.item3.txtPart3')}
			</p>
		),
	},
	{
		icon: <VisualPresentation />,
		title: t('landing.features.list.item4.title'),
		text: (
			<p>
				{t('landing.features.list.item4.txtPart1')}
				<span> {t('landing.features.list.item4.txtPart2')} </span>
				{t('landing.features.list.item4.txtPart3')}
				<span> {t('landing.features.list.item4.txtPart4')} </span>
				{t('landing.features.list.item4.txtPart5')}
				<span> {t('landing.features.list.item4.txtPart6')} </span>
			</p>
		),
	},
	{
		icon: <Automation />,
		title: t('landing.features.list.item5.title'),
		text: (
			<p>
				<span>{t('landing.features.list.item5.txtPart1')} </span>
				{t('landing.features.list.item5.txtPart2')}
				<span> {t('landing.features.list.item5.txtPart3')} </span>
				{t('landing.features.list.item5.txtPart4')}
			</p>
		),
	},
	{
		icon: <Api />,
		title: t('landing.features.list.item6.title'),
		text: (
			<p>
				<span>{t('landing.features.list.item6.txtPart1')} </span>
				{t('landing.features.list.item6.txtPart2')}
			</p>
		),
	},
];
