import './styles/App.css';
import styled from 'styled-components';
import Header from './components/Header';
import { FC, useEffect } from 'react';
import Footer from './components/Footer';
import MainBlock from './components/MainBlock';
import Features from './components/Features/Features';
import SignUp from './components/SignUp';
import * as Sentry from '@sentry/react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Article } from 'schema-dts';
import { useTranslation } from 'react-i18next';
import { GeneralStore } from './stores/general';

const App: FC = () => {
	const { i18n } = useTranslation();

	useEffect(() => {
		GeneralStore.changeLanguage(i18n.language, i18n).then(
			() => (GeneralStore.loadingDictionary = false)
		);
	}, [i18n]);

	return (
		<Sentry.ErrorBoundary fallback={(props) => <p>Тут будет красивая ошибка</p>}>
			<HelmetProvider>
				<MainWrapper>
					<Helmet
						script={[
							helmetJsonLdProp<Article>({
								'@context': 'https://schema.org',
								'@type': 'Article',
								headline: 'Cubekit',
								description:
									'AI Powered Web Analyzer. AI Web data extraction and analysis tools will automates data extraction from news related Web sites using Machine Learning.',
								image: 'https://cubekit.com/static/media/Analyze.6e7751d878e943076dc4.jpg',
								author: {
									'@type': 'Organization',
									name: 'Cubekit',
									url: 'https://cubekit.com',
								},
							}),
						]}
					/>
					;
					<Header />
					<MainBlock />
					<Features />
					<SignUp />
					<Footer />
				</MainWrapper>
			</HelmetProvider>
		</Sentry.ErrorBoundary>
	);
};

const MainWrapper = styled.div`
	width: 100%;
`;

export default App;
