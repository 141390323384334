import { FC } from "react";

const VisualPresentation:FC = () => {
    return(
      <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_75_471)">
          <path d="M41.3235 25.7899H7.66641C7.30034 25.7899 6.94926 25.642 6.69041 25.3788C6.43155 25.1156 6.28613 24.7587 6.28613 24.3865V1.40686C6.28613 1.22153 6.32214 1.03802 6.39211 0.866884C6.46207 0.695745 6.5646 0.540348 6.69381 0.40962C6.82301 0.278892 6.97635 0.175409 7.14501 0.105116C7.31368 0.0348232 7.49433 -0.000893738 7.67661 1.69874e-05H41.3337C41.7001 0.00093227 42.0512 0.149557 42.3099 0.413292C42.5687 0.677028 42.714 1.03434 42.714 1.40686V24.3865C42.714 24.5716 42.678 24.755 42.608 24.9259C42.538 25.0969 42.4354 25.252 42.3061 25.3825C42.1769 25.5129 42.0235 25.6161 41.8549 25.686C41.6862 25.7559 41.5056 25.7912 41.3235 25.7899ZM7.67661 1.38266L7.65622 24.3865L41.3133 24.4072C41.316 24.4072 41.3187 24.4067 41.3211 24.4056C41.3236 24.4046 41.3259 24.4031 41.3278 24.4011C41.3296 24.3992 41.3312 24.3969 41.3322 24.3944C41.3332 24.3919 41.3337 24.3892 41.3337 24.3865V1.40686L7.67661 1.38266Z" fill="#4F8BFF"/>
          <path d="M10.835 8.89739H9.4751V4.61463C9.476 4.28979 9.60355 3.97856 9.82979 3.74918C10.056 3.5198 10.3625 3.39099 10.682 3.39099H14.8942V4.77364H10.835V8.89739Z" fill="#4F8BFF"/>
          <path d="M39.4638 8.89739H38.1039V4.77364H34.0447V3.39099H38.2569C38.5764 3.39099 38.8829 3.5198 39.1091 3.74918C39.3354 3.97856 39.4629 4.28979 39.4638 4.61463V8.89739Z" fill="#4F8BFF"/>
          <path d="M38.2569 22.627H34.0447V21.2444H38.1039V17.1172H39.4638V21.3999C39.4638 21.7254 39.3367 22.0375 39.1103 22.2676C38.884 22.4978 38.577 22.627 38.2569 22.627Z" fill="#4F8BFF"/>
          <path d="M14.8942 22.627H10.682C10.3619 22.627 10.0549 22.4978 9.82859 22.2676C9.60225 22.0375 9.4751 21.7254 9.4751 21.3999V17.1172H10.835V21.2444H14.8942V22.627Z" fill="#4F8BFF"/>
          <path d="M45.0631 40.363H3.92666C2.88459 40.3593 1.88637 39.9361 1.15046 39.1859C0.414556 38.4358 0.000892917 37.4197 0 36.3602L0 10.6049C0.000900255 9.54362 0.415965 8.52603 1.15407 7.77557C1.89218 7.0251 2.89302 6.60309 3.93686 6.60217H6.86401V7.98482H3.93686C3.25368 7.98574 2.59874 8.26208 2.11566 8.75325C1.63257 9.24441 1.36078 9.91032 1.35988 10.6049V36.3602C1.36078 37.0548 1.63257 37.7207 2.11566 38.2119C2.59874 38.7031 3.25368 38.9794 3.93686 38.9803H45.0733C45.7565 38.9794 46.4115 38.7031 46.8945 38.2119C47.3776 37.7207 47.6494 37.0548 47.6503 36.3602V10.6049C47.6494 9.91032 47.3776 9.24441 46.8945 8.75325C46.4115 8.26208 45.7565 7.98574 45.0733 7.98482H42.1122V6.60217H45.0733C46.1172 6.60309 47.118 7.0251 47.8561 7.77557C48.5942 8.52603 49.0093 9.54362 49.0102 10.6049V36.3602C49.0098 36.8866 48.9073 37.4078 48.7086 37.8939C48.51 38.38 48.2191 38.8215 47.8525 39.1932C47.486 39.565 47.0509 39.8596 46.5723 40.0603C46.0937 40.261 45.5809 40.3639 45.0631 40.363Z" fill="#4F8BFF"/>
          <path d="M48.32 32.5579H0.679932V33.9405H48.32V32.5579Z" fill="#4F8BFF"/>
          <path d="M4.24966 35.859H3.04956V37.0792H4.24966V35.859Z" fill="#4F8BFF"/>
          <path d="M24.4984 19.3053C23.2509 19.3053 22.0315 18.9291 20.9943 18.2244C19.9572 17.5197 19.1488 16.5181 18.6716 15.3462C18.1944 14.1744 18.0697 12.8849 18.3133 11.641C18.5569 10.3971 19.1579 9.25462 20.0402 8.35802C20.9225 7.46142 22.0466 6.85101 23.2701 6.60399C24.4937 6.35697 25.7618 6.48444 26.9141 6.97028C28.0664 7.45612 29.0511 8.27851 29.7436 9.33342C30.4362 10.3883 30.8055 11.6284 30.8048 12.8967C30.803 14.5964 30.1379 16.2259 28.9555 17.4274C27.7731 18.629 26.1701 19.3043 24.4984 19.3053ZM24.4984 7.86731C23.52 7.86731 22.5636 8.16228 21.7502 8.71491C20.9367 9.26755 20.3027 10.053 19.9283 10.972C19.5539 11.891 19.456 12.9023 19.6468 13.8779C19.8377 14.8535 20.3088 15.7496 21.0006 16.453C21.6924 17.1564 22.5738 17.6354 23.5333 17.8294C24.4929 18.0235 25.4875 17.9239 26.3913 17.5432C27.2952 17.1626 28.0677 16.5179 28.6113 15.6909C29.1548 14.8638 29.4449 13.8914 29.4449 12.8967C29.444 11.5631 28.9226 10.2844 27.9951 9.34139C27.0677 8.3984 25.81 7.86822 24.4984 7.86731Z" fill="#4F8BFF"/>
          <path d="M24.4983 16.0387C23.8862 16.0387 23.288 15.8542 22.7791 15.5084C22.2703 15.1626 21.8738 14.6711 21.6397 14.0961C21.4057 13.5212 21.3446 12.8885 21.4642 12.2783C21.5839 11.668 21.8789 11.1075 22.3119 10.6678C22.7449 10.228 23.2965 9.92868 23.8968 9.8077C24.4972 9.68672 25.1193 9.74951 25.6846 9.98811C26.2498 10.2267 26.7327 10.6304 27.0723 11.1482C27.4118 11.6659 27.5927 12.2744 27.592 12.8967C27.5911 13.7303 27.2648 14.5295 26.6847 15.1186C26.1046 15.7078 25.3182 16.0387 24.4983 16.0387ZM24.4983 11.1338C24.1554 11.1338 23.8201 11.2372 23.535 11.4309C23.2499 11.6246 23.0276 11.8999 22.8964 12.222C22.7652 12.5442 22.7308 12.8986 22.7977 13.2406C22.8646 13.5825 23.0298 13.8967 23.2723 14.1432C23.5147 14.3897 23.8237 14.5576 24.16 14.6257C24.4964 14.6937 24.845 14.6588 25.1618 14.5253C25.4786 14.3919 25.7494 14.166 25.9399 13.8761C26.1304 13.5862 26.2321 13.2453 26.2321 12.8967C26.2321 12.6652 26.1873 12.4359 26.1001 12.222C26.013 12.0082 25.8853 11.8138 25.7243 11.6501C25.5633 11.4864 25.3722 11.3566 25.1618 11.268C24.9514 11.1794 24.726 11.1338 24.4983 11.1338Z" fill="#4F8BFF"/>
          <path d="M24.4984 19.3053C16.2711 19.3053 12.3513 13.5258 12.1915 13.2804L11.9297 12.8863L12.1983 12.4992C13.6588 10.6061 15.5274 9.07937 17.66 8.03659C19.7925 6.99381 22.1321 6.46285 24.4984 6.48465C26.8849 6.47685 29.243 7.01165 31.4 8.0499C33.557 9.08815 35.4584 10.6036 36.9651 12.4853L37.2507 12.8863L36.9719 13.2942C36.802 13.5396 32.7189 19.3053 24.4984 19.3053ZM13.6193 12.9036C14.9619 14.4975 16.6318 15.7729 18.5113 16.64C20.3909 17.5071 22.4345 17.9449 24.4984 17.9226C26.5841 17.9351 28.6482 17.4937 30.5522 16.628C32.4561 15.7623 34.1559 14.4924 35.5373 12.9036C34.1445 11.3278 32.4429 10.0656 30.5418 9.19827C28.6407 8.33092 26.5821 7.87756 24.4984 7.8673C22.4362 7.86627 20.3978 8.31536 18.5209 9.18421C16.6441 10.0531 14.9726 11.3214 13.6193 12.9036Z" fill="#4F8BFF"/>
          <path d="M41.116 48.6173H7.85327V50H41.116V48.6173Z" fill="#4F8BFF"/>
          <path d="M16.2336 49.8514L15.3973 48.7626C15.4143 48.7626 17.3725 47.1449 17.3725 44.5904C17.3725 42.036 15.3871 40.2109 15.3667 40.1936L16.2608 39.1566C16.3594 39.2465 18.7324 41.3758 18.7324 44.5973C18.7324 47.8189 16.3186 49.7754 16.2336 49.8514Z" fill="#4F8BFF"/>
          <path d="M32.6847 49.8306C32.5861 49.7407 30.2131 47.6115 30.2131 44.3899C30.2131 41.1683 32.5929 39.205 32.7119 39.1289L33.5482 40.2177C33.5312 40.2177 31.573 41.8354 31.573 44.3899C31.573 46.9443 33.5584 48.766 33.5754 48.7832L32.6847 49.8306Z" fill="#4F8BFF"/>
        </g>
        <defs>
          <clipPath id="clip0_75_471">
            <rect width="49" height="50" fill="white"/>
          </clipPath>
        </defs>
      </svg>

    )
  }
export default VisualPresentation;