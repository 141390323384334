import { FC } from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { getData } from './ItemsData';
import Item from './Item';
import { useTranslation } from 'react-i18next';

const Features: FC = () => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<Title>{t('landing.features.title')}</Title>
			<Subheader>
				<span>{t('landing.features.subHeaderPart1')}</span> {t('landing.features.subHeaderPart2')}
			</Subheader>
			<List>
				{getData(t).map((item, index) => {
					return (
						<Item
							key={index}
							item={item}
						/>
					);
				})}
			</List>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	max-width: 1136px;
	margin: 0 auto 56px;

	@media (max-width: 740px) {
		padding: 0 20px;
	}
`;

const Title = styled.h2`
	color: #ffffff;
	text-align: center;
	font-size: 36px;
	font-family: ${theme.fonts.mainBold};
	line-height: 44px;
	margin-bottom: 25px;

	@media (max-width: 376px) {
		font-size: 30px;
		line-height: 36px;
	}
`;

const Subheader = styled.div`
	text-align: center;
	color: #7b889d;
	line-height: 1.6;
	font-size: 14px;
	font-family: ${theme.fonts.mainBold};
	margin-bottom: 97px;

	@media (max-width: 740px) {
		margin-bottom: 83px;
	}

	span {
		color: #6385c7;
	}
`;

const List = styled.div`
	display: flex;
	gap: 40px;
	flex-wrap: wrap;
	justify-content: center;

	@media (max-width: 740px) {
		gap: 60px;
	}
`;

export default Features;
