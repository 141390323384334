import { FC } from "react";

const ItAcesLogo: FC = () => {
    return(
      <svg width="41" height="33" viewBox="0 0 41 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_108_1071)">
          <path d="M20.8263 12.3409C19.7467 11.5284 18.4696 11.0192 17.1271 10.8659C15.7846 10.7126 14.4256 10.9208 13.1906 11.469C13.3184 11.5691 13.4199 11.6988 13.4864 11.8469C13.553 11.9949 13.5825 12.157 13.5724 12.319C13.5624 12.481 13.5132 12.6382 13.4289 12.7769C13.3446 12.9156 13.2279 13.0318 13.0887 13.1154C12.9496 13.199 12.7922 13.2475 12.6302 13.2568C12.4681 13.2661 12.3062 13.2358 12.1585 13.1686C12.0107 13.1013 11.8815 12.9992 11.782 12.871C11.6825 12.7427 11.6156 12.5922 11.5872 12.4324C11.0685 12.8394 10.6052 13.3124 10.2091 13.8395L1.10162 25.9352C2.42215 26.9295 4.03141 27.4649 5.68438 27.4601C7.33734 27.4553 8.94344 26.9104 10.2581 25.9084L11.3018 17.615L9.62927 17.7398L9.88127 16.4643L14.2231 13.1393L13.0591 22.6615L20.8263 12.3409Z" fill="white"/>
          <path d="M15.3047 4.33306L11.8036 32.9444C13.7986 33.1868 15.8083 32.6273 17.391 31.3887C18.9738 30.1501 20 28.3339 20.2442 26.339L21.0738 19.5597L21.948 12.4058C22.1565 10.5456 22.7379 8.74659 23.6575 7.11624C24.5771 5.48589 25.816 4.05769 27.3 2.91699V2.91699L15.3047 4.33306Z" fill="white"/>
          <path d="M16.6606 1.61005L0 14.6379C0.613443 15.4239 1.3759 16.0813 2.24372 16.5723C3.11155 17.0632 4.06769 17.3782 5.05741 17.4992C6.04713 17.6202 7.05099 17.5447 8.01152 17.2772C8.97205 17.0097 9.87039 16.5554 10.6551 15.9402L27.3045 2.91239C26.6912 2.12794 25.9293 1.47199 25.0624 0.981991C24.1956 0.491994 23.2407 0.177549 22.2523 0.0566143C21.2639 -0.0643209 20.2613 0.0106203 19.3019 0.277161C18.3425 0.543701 17.4449 0.99662 16.6606 1.61005V1.61005ZM12.7134 13.2508C12.5168 13.2785 12.3165 13.2472 12.1377 13.1609C11.9589 13.0747 11.8097 12.9374 11.709 12.7663C11.6083 12.5953 11.5605 12.3982 11.5718 12.2C11.5831 12.0018 11.6529 11.8115 11.7724 11.6529C11.8919 11.4944 12.0557 11.3749 12.2431 11.3096C12.4306 11.2442 12.6332 11.2358 12.8253 11.2856C13.0175 11.3354 13.1906 11.441 13.3227 11.5892C13.4548 11.7374 13.54 11.9214 13.5675 12.118C13.5861 12.2485 13.5787 12.3813 13.5458 12.509C13.5129 12.6366 13.4551 12.7565 13.3757 12.8618C13.2964 12.967 13.197 13.0556 13.0834 13.1223C12.9697 13.1891 12.844 13.2328 12.7134 13.2508V13.2508Z" fill="white"/>
        </g>
        <path d="M28.3375 30.7297C28.2661 30.7297 28.2304 30.6976 28.2304 30.6334L28.1127 29.1776C28.1127 29.1633 28.1056 29.1526 28.0913 29.1455C28.0842 29.1384 28.0734 29.1348 28.0592 29.1348H25.9183C25.9041 29.1348 25.8898 29.1384 25.8755 29.1455C25.8684 29.1526 25.8648 29.1633 25.8648 29.1776L25.3831 30.6334C25.3617 30.6976 25.3189 30.7297 25.2547 30.7297H25.062C25.0263 30.7297 25.0014 30.719 24.9871 30.6976C24.9728 30.6762 24.9728 30.6477 24.9871 30.612L27.4597 23.3332C27.474 23.2689 27.5133 23.2368 27.5775 23.2368H27.8451C27.9093 23.2368 27.9414 23.2689 27.9414 23.3332L28.6372 30.612C28.6372 30.6905 28.6015 30.7297 28.5302 30.7297H28.3375ZM26.0147 28.7387C26.0075 28.7459 26.0075 28.7566 26.0147 28.7709C26.0218 28.778 26.029 28.7816 26.0361 28.7816H28.0271C28.0413 28.7816 28.052 28.778 28.0592 28.7709C28.0734 28.7566 28.077 28.7459 28.0699 28.7387L27.6524 23.954C27.6453 23.9326 27.6381 23.9219 27.631 23.9219C27.6239 23.9219 27.6167 23.9326 27.6096 23.954L26.0147 28.7387Z" fill="white"/>
        <path d="M30.7698 30.8154C30.3773 30.8154 30.0598 30.694 29.8172 30.4514C29.5817 30.2088 29.4639 29.8877 29.4639 29.488C29.4639 29.4381 29.4711 29.356 29.4853 29.2418L29.7743 26.8441C29.8314 26.3803 30.0134 26.0021 30.3203 25.7095C30.6342 25.4169 31.0089 25.2706 31.4442 25.2706C31.851 25.2706 32.1721 25.3884 32.4076 25.6238C32.6502 25.8593 32.7715 26.1483 32.7715 26.4909C32.7715 26.5622 32.7679 26.6158 32.7608 26.6514V26.6943C32.7537 26.7228 32.7394 26.7478 32.718 26.7692C32.6966 26.7906 32.6716 26.8013 32.6431 26.8013L32.4397 26.812C32.3683 26.812 32.3398 26.7763 32.354 26.705V26.6621L32.3648 26.5444C32.3648 26.2946 32.2756 26.0841 32.0971 25.9129C31.9259 25.7345 31.694 25.6453 31.4014 25.6453C31.0874 25.6453 30.8162 25.7594 30.5879 25.9878C30.3595 26.209 30.2239 26.498 30.1811 26.8548L29.8921 29.2311C29.885 29.274 29.8814 29.3382 29.8814 29.4238C29.8814 29.7235 29.967 29.9697 30.1383 30.1624C30.3096 30.3479 30.5343 30.4407 30.8127 30.4407C31.1266 30.4407 31.3978 30.3372 31.6262 30.1303C31.8545 29.9233 31.983 29.6879 32.0115 29.4238L32.0222 29.381C32.0365 29.3096 32.0757 29.274 32.14 29.274H32.3326C32.3683 29.274 32.3933 29.2847 32.4076 29.3061C32.429 29.3275 32.4361 29.3525 32.429 29.381L32.4183 29.4345C32.3897 29.6771 32.3005 29.9055 32.1507 30.1196C32.0008 30.3265 31.8046 30.4942 31.5619 30.6227C31.3193 30.7511 31.0553 30.8154 30.7698 30.8154Z" fill="white"/>
        <path d="M36.5605 28.0537C36.5463 28.125 36.507 28.1607 36.4428 28.1607H34.0343C34.0058 28.1607 33.9915 28.175 33.9915 28.2035L33.8631 29.2311C33.8559 29.274 33.8524 29.3382 33.8524 29.4238C33.8524 29.7307 33.9344 29.9769 34.0986 30.1624C34.2698 30.3479 34.4982 30.4407 34.7836 30.4407C35.0405 30.4407 35.2725 30.3622 35.4794 30.2052C35.6935 30.0411 35.8469 29.8306 35.9397 29.5737C35.9754 29.5094 36.0182 29.4773 36.0681 29.4773L36.2501 29.488C36.2858 29.488 36.3108 29.4987 36.325 29.5202C36.3393 29.5416 36.3429 29.5701 36.3357 29.6058C36.2216 29.9769 36.0218 30.273 35.7363 30.4942C35.4509 30.7083 35.119 30.8154 34.7408 30.8154C34.3483 30.8154 34.0308 30.6976 33.7882 30.4621C33.5527 30.2195 33.4349 29.8984 33.4349 29.4987C33.4349 29.4488 33.4421 29.3667 33.4563 29.2525L33.7453 26.8441C33.8024 26.3803 33.9844 26.0021 34.2912 25.7095C34.6052 25.4169 34.9799 25.2706 35.4152 25.2706C35.8219 25.2706 36.1395 25.3955 36.3679 25.6453C36.6033 25.8879 36.7211 26.209 36.7211 26.6086C36.7211 26.7085 36.7175 26.787 36.7104 26.8441L36.5605 28.0537ZM35.3724 25.6453C35.0584 25.6453 34.7872 25.7594 34.5589 25.9878C34.3305 26.209 34.1949 26.498 34.1521 26.8548L34.0451 27.7647C34.0379 27.7718 34.0379 27.7825 34.0451 27.7968C34.0522 27.8039 34.0629 27.8075 34.0772 27.8075H36.1431C36.1716 27.8075 36.1859 27.7932 36.1859 27.7647L36.3036 26.8548C36.3108 26.812 36.3143 26.7478 36.3143 26.6621C36.3143 26.3553 36.2287 26.1091 36.0574 25.9236C35.8862 25.738 35.6578 25.6453 35.3724 25.6453Z" fill="white"/>
        <path d="M38.6145 30.7725C38.2291 30.7725 37.9223 30.6691 37.6939 30.4621C37.4656 30.248 37.3514 29.9733 37.3514 29.6379C37.3514 29.5594 37.3549 29.4987 37.3621 29.4559V29.4024C37.3764 29.331 37.4156 29.2954 37.4798 29.2954H37.6725C37.7439 29.2954 37.7724 29.331 37.7581 29.4024V29.4559C37.751 29.4916 37.7474 29.538 37.7474 29.5951C37.7474 29.8377 37.8295 30.0375 37.9936 30.1945C38.1578 30.3444 38.3825 30.4193 38.668 30.4193C38.9963 30.4193 39.2639 30.3087 39.4708 30.0875C39.6849 29.8663 39.7919 29.5808 39.7919 29.2311C39.7919 28.9671 39.7099 28.7673 39.5457 28.6317C39.3816 28.489 39.1318 28.3427 38.7964 28.1928C38.4468 28.0358 38.1756 27.8681 37.9829 27.6897C37.7974 27.5113 37.7046 27.2509 37.7046 26.9083C37.7046 26.8227 37.7082 26.7549 37.7153 26.705C37.7653 26.2839 37.933 25.9485 38.2184 25.6988C38.5039 25.4419 38.8535 25.3134 39.2674 25.3134C39.6456 25.3134 39.9454 25.4205 40.1666 25.6345C40.3878 25.8415 40.4984 26.1198 40.4984 26.4695C40.4984 26.5694 40.4948 26.6443 40.4877 26.6943V26.7264C40.4734 26.7977 40.4342 26.8334 40.3699 26.8334H40.1987C40.163 26.8334 40.1345 26.8227 40.113 26.8013C40.0988 26.7799 40.0952 26.7549 40.1023 26.7264L40.113 26.6836L40.1238 26.5551C40.1238 26.2982 40.0417 26.0877 39.8776 25.9236C39.7134 25.7523 39.4958 25.6667 39.2246 25.6667C38.8963 25.6667 38.6252 25.7808 38.4111 26.0092C38.2041 26.2304 38.1007 26.5087 38.1007 26.8441C38.1007 27.0939 38.1756 27.2866 38.3255 27.4221C38.4825 27.5506 38.7179 27.6897 39.0319 27.8396C39.2888 27.9538 39.4922 28.0573 39.6421 28.15C39.7919 28.2428 39.9204 28.3712 40.0274 28.5354C40.1416 28.6995 40.1987 28.9064 40.1987 29.1562C40.1987 29.6415 40.0524 30.034 39.7598 30.3337C39.4672 30.6262 39.0855 30.7725 38.6145 30.7725Z" fill="white"/>
        <path d="M27.3406 20.6946C27.3025 20.6946 27.2692 20.6804 27.2406 20.6518C27.2216 20.6233 27.2169 20.59 27.2264 20.5519L28.411 10.8468C28.4205 10.8088 28.4395 10.7755 28.4681 10.7469C28.4966 10.7184 28.5299 10.7041 28.568 10.7041H29.6241C29.7097 10.7041 29.7526 10.7517 29.7526 10.8468L28.5537 20.5519C28.5347 20.6471 28.4823 20.6946 28.3967 20.6946H27.3406Z" fill="white"/>
        <path d="M36.0736 10.7041C36.1116 10.7041 36.1402 10.7184 36.1592 10.7469C36.1878 10.7755 36.1973 10.8088 36.1878 10.8468L36.0736 11.746C36.0736 11.784 36.0593 11.8173 36.0308 11.8459C36.0022 11.8744 35.9642 11.8887 35.9166 11.8887H34.104C34.066 11.8887 34.0469 11.9077 34.0469 11.9458L32.9908 20.5519C32.9718 20.6471 32.9194 20.6946 32.8338 20.6946H31.7776C31.6825 20.6946 31.6444 20.6471 31.6635 20.5519L32.7196 11.9458C32.7196 11.9077 32.7006 11.8887 32.6625 11.8887H30.9499C30.8547 11.8887 30.8167 11.8411 30.8357 11.746L30.9356 10.8468C30.9451 10.8088 30.9641 10.7755 30.9927 10.7469C31.0212 10.7184 31.0545 10.7041 31.0926 10.7041H36.0736Z" fill="white"/>
        <defs>
          <clipPath id="clip0_108_1071">
            <rect width="27.3045" height="33" fill="white"/>
          </clipPath>
        </defs>
      </svg>

    )
  }
export default ItAcesLogo;