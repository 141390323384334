import { FC } from 'react';

const EllipseBlur: FC = () => {
	return (
		<svg
			width='1920'
			height='1134'
			viewBox='0 0 1920 1134'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g filter='url(#filter0_f_108_1048)'>
				<circle
					cx='915'
					cy='-153'
					r='687'
					fill='#272531'
				/>
			</g>
			<defs>
				<filter
					id='filter0_f_108_1048'
					x='-372'
					y='-1440'
					width='2574'
					height='2574'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'>
					<feFlood
						floodOpacity='0'
						result='BackgroundImageFix'
					/>
					<feBlend
						mode='normal'
						in='SourceGraphic'
						in2='BackgroundImageFix'
						result='shape'
					/>
					<feGaussianBlur
						stdDeviation='300'
						result='effect1_foregroundBlur_108_1048'
					/>
				</filter>
			</defs>
		</svg>
	);
};
export default EllipseBlur;
