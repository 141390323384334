import { FC } from 'react';
import styled from 'styled-components';
import ItAcesLogo from '../assets/icons/ItAcesLogo';
import { theme } from '../styles/theme';

const Footer: FC = () => {
	return (
		<FooterWrapper>
			<ItAcesLogo />
			<Year>2024</Year>
		</FooterWrapper>
	);
};

const FooterWrapper = styled.footer`
	padding: 11px 10px;
	display: flex;
	align-items: center;
`;

const Year = styled.div`
	color: #7b889d;
	font-size: 14px;
	font-family: ${theme.fonts.mainRegular};
	margin-left: 20px;
`;

export default Footer;