import { FC } from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

interface Props {
	item: any;
}

const Item: FC<Props> = ({ item }) => {
	return (
		<Wrapper>
			<IconWrapper>{item.icon}</IconWrapper>
			<Title>{item.title}</Title>
			{item.text}
		</Wrapper>
	);
};

const Title = styled.h4`
	margin-bottom: 5px;
	text-align: center;
	color: #ffffff;
	font-size: 20px;
	line-height: 1.6;
	font-family: ${theme.fonts.mainBold};
`;

const IconWrapper = styled.div`
	margin: 0 auto 40px;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: rgba(99, 133, 199, 0.1);
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Wrapper = styled.div`
	max-width: 350px;
	width: 100%;
	margin-bottom: 77px;

	@media (max-width: 740px) {
		margin-bottom: 0;
		width: calc(100% / 2 - 50px);
	}

	@media (max-width: 376px) {
		width: auto;
		max-width: 320px;
	}

	p {
		text-align: center;
		color: #7b889d;
		font-family: ${theme.fonts.mainBold};
		font-size: 14px;
		line-height: 1.6;
		height: 94px;

		span {
			color: ${theme.colors.primary};
		}
	}
`;

export default Item;
