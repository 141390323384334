import { FC } from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

interface Props {
  text: string;
  mainWidth: number;
  onClick: () => void;
}

const Button:FC<Props> = ({text, mainWidth, onClick}) => {

	return (
		<Wrapper
			mainWidth={mainWidth}
			className={'button'}
			onClick={onClick}>
			{text}
		</Wrapper>
	);
};

const Wrapper = styled.div<{mainWidth: number}>`
	width: ${(props) => props.mainWidth}px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-size: 15px;
	font-family: ${theme.fonts.mainMedium};
	border-radius: 5px;
	background-color: ${theme.colors.primary};
	cursor: pointer;
	line-height: 1.4;
`;

export default Button;