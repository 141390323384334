import { FC } from 'react';

const LangRuIcon: FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'>
			<g clipPath='url(#clip0_119_862)'>
				<mask
					id='mask0_119_862'
					style={{ maskType: 'alpha' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='3'
					width='18'
					height='12'>
					<path
						d='M16.2857 3H1.71429C0.767512 3 0 3.71634 0 4.6V13.4C0 14.2837 0.767512 15 1.71429 15H16.2857C17.2325 15 18 14.2837 18 13.4V4.6C18 3.71634 17.2325 3 16.2857 3Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask0_119_862)'>
					<path
						d='M16.2857 3H1.71429C0.767512 3 0 3.71634 0 4.6V13.4C0 14.2837 0.767512 15 1.71429 15H16.2857C17.2325 15 18 14.2837 18 13.4V4.6C18 3.71634 17.2325 3 16.2857 3Z'
						fill='#0034A9'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 11H18V15H0V11Z'
						fill='#D7280F'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 3H18V7H0V3Z'
						fill='white'
					/>
					<path
						d='M16.2856 3.40002H1.71418C1.0041 3.40002 0.428467 3.93728 0.428467 4.60002V13.4C0.428467 14.0628 1.0041 14.6 1.71418 14.6H16.2856C16.9957 14.6 17.5713 14.0628 17.5713 13.4V4.60002C17.5713 3.93728 16.9957 3.40002 16.2856 3.40002Z'
						stroke='black'
						strokeOpacity='0.1'
					/>
				</g>
			</g>
			<defs>
				<clipPath id='clip0_119_862'>
					<rect
						width='18'
						height='18'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default LangRuIcon;
