import { FC } from 'react';

const Customization: FC = () => {
	return (
		<svg
			width='51'
			height='43'
			viewBox='0 0 51 43'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_75_538)'>
				<path
					d='M41.174 23.5637H39.814V27.3292H41.174V23.5637Z'
					fill='#4F8BFF'
				/>
				<path
					d='M37.434 43H3.74C2.74809 43 1.79681 42.6079 1.09542 41.91C0.394035 41.2121 0 40.2655 0 39.2785L0 3.72148C0 2.73448 0.394035 1.78791 1.09542 1.09C1.79681 0.392083 2.74809 0 3.74 0L37.434 0C38.4259 0 39.3772 0.392083 40.0786 1.09C40.78 1.78791 41.174 2.73448 41.174 3.72148V12.6361H39.814V3.72148C39.814 3.09339 39.5633 2.49103 39.1169 2.0469C38.6706 1.60277 38.0652 1.35327 37.434 1.35327H3.74C3.10878 1.35327 2.50342 1.60277 2.05709 2.0469C1.61075 2.49103 1.36 3.09339 1.36 3.72148V39.2785C1.36 39.9066 1.61075 40.509 2.05709 40.9531C2.50342 41.3972 3.10878 41.6467 3.74 41.6467H37.434C38.0652 41.6467 38.6706 41.3972 39.1169 40.9531C39.5633 40.509 39.814 39.9066 39.814 39.2785V37.9794H41.174V39.2785C41.174 40.2655 40.78 41.2121 40.0786 41.91C39.3772 42.6079 38.4259 43 37.434 43Z'
					fill='#4F8BFF'
				/>
				<path
					d='M49.3001 24.0983H14.2801C13.8292 24.0983 13.3968 23.9201 13.078 23.6028C12.7592 23.2856 12.5801 22.8554 12.5801 22.4067V13.9691C12.5801 13.5205 12.7592 13.0902 13.078 12.773C13.3968 12.4557 13.8292 12.2775 14.2801 12.2775H49.3001C49.7509 12.2775 50.1833 12.4557 50.5022 12.773C50.821 13.0902 51.0001 13.5205 51.0001 13.9691V22.3932C51.0019 22.6165 50.9592 22.8379 50.8746 23.0447C50.79 23.2515 50.665 23.4396 50.507 23.5981C50.349 23.7566 50.1609 23.8824 49.9538 23.9683C49.7467 24.0541 49.5245 24.0983 49.3001 24.0983ZM14.2801 13.6105C14.1899 13.6105 14.1034 13.6461 14.0397 13.7096C13.9759 13.773 13.9401 13.8591 13.9401 13.9488V22.3729C13.9401 22.4626 13.9759 22.5487 14.0397 22.6121C14.1034 22.6756 14.1899 22.7112 14.2801 22.7112H49.3001C49.3903 22.7112 49.4767 22.6756 49.5405 22.6121C49.6043 22.5487 49.6401 22.4626 49.6401 22.3729V13.9691C49.6401 13.8794 49.6043 13.7933 49.5405 13.7299C49.4767 13.6664 49.3903 13.6308 49.3001 13.6308L14.2801 13.6105Z'
					fill='#4F8BFF'
				/>
				<path
					d='M49.3001 38.5478H14.2801C13.8292 38.5478 13.3968 38.3695 13.078 38.0523C12.7592 37.7351 12.5801 37.3048 12.5801 36.8562V28.4186C12.5801 27.9699 12.7592 27.5397 13.078 27.2224C13.3968 26.9052 13.8292 26.727 14.2801 26.727H49.3001C49.7509 26.727 50.1833 26.9052 50.5022 27.2224C50.821 27.5397 51.0001 27.9699 51.0001 28.4186V36.8426C51.0019 37.0659 50.9592 37.2873 50.8746 37.4941C50.79 37.7009 50.665 37.889 50.507 38.0475C50.349 38.206 50.1609 38.3319 49.9538 38.4177C49.7467 38.5036 49.5245 38.5478 49.3001 38.5478ZM14.2801 28.0803C14.1899 28.0803 14.1034 28.1159 14.0397 28.1793C13.9759 28.2428 13.9401 28.3288 13.9401 28.4186V36.8426C13.9401 36.9324 13.9759 37.0184 14.0397 37.0819C14.1034 37.1453 14.1899 37.181 14.2801 37.181H49.3001C49.3903 37.181 49.4767 37.1453 49.5405 37.0819C49.6043 37.0184 49.6401 36.9324 49.6401 36.8426V28.4186C49.6401 28.3288 49.6043 28.2428 49.5405 28.1793C49.4767 28.1159 49.3903 28.0803 49.3001 28.0803H14.2801Z'
					fill='#4F8BFF'
				/>
				<path
					d='M40.4939 7.85907H0.679932V9.21233H40.4939V7.85907Z'
					fill='#4F8BFF'
				/>
				<path
					d='M37.1587 3.8399H12.8555V5.19317H37.1587V3.8399Z'
					fill='#4F8BFF'
				/>
				<path
					d='M5.8141 3.8399H4.4541V5.19317H5.8141V3.8399Z'
					fill='#4F8BFF'
				/>
				<path
					d='M8.31288 3.8399H6.95288V5.19317H8.31288V3.8399Z'
					fill='#4F8BFF'
				/>
				<path
					d='M10.8153 3.8399H9.45532V5.19317H10.8153V3.8399Z'
					fill='#4F8BFF'
				/>
				<path
					d='M26.8056 15.7148H17.374V17.068H26.8056V15.7148Z'
					fill='#4F8BFF'
				/>
				<path
					d='M46.8995 15.7148H30.7971V17.068H46.8995V15.7148Z'
					fill='#4F8BFF'
				/>
				<path
					d='M20.0056 19.2502H17.374V20.6034H20.0056V19.2502Z'
					fill='#4F8BFF'
				/>
				<path
					d='M25.3572 19.2502H22.5046V20.6034H25.3572V19.2502Z'
					fill='#4F8BFF'
				/>
				<path
					d='M36.5024 19.2502H27.8154V20.6034H36.5024V19.2502Z'
					fill='#4F8BFF'
				/>
				<path
					d='M46.8996 19.2502H38.5186V20.6034H46.8996V19.2502Z'
					fill='#4F8BFF'
				/>
				<path
					d='M7.63307 13.8506H4.72607V15.2039H7.63307V13.8506Z'
					fill='#4F8BFF'
				/>
				<path
					d='M7.63307 17.5045H4.72607V18.8577H7.63307V17.5045Z'
					fill='#4F8BFF'
				/>
				<path
					d='M7.63307 20.8335H4.72607V22.1868H7.63307V20.8335Z'
					fill='#4F8BFF'
				/>
				<path
					d='M7.63307 24.1625H4.72607V25.5158H7.63307V24.1625Z'
					fill='#4F8BFF'
				/>
				<path
					d='M7.63307 27.4916H4.72607V28.8448H7.63307V27.4916Z'
					fill='#4F8BFF'
				/>
				<path
					d='M7.63307 30.8206H4.72607V32.1739H7.63307V30.8206Z'
					fill='#4F8BFF'
				/>
				<path
					d='M7.63307 34.1497H4.72607V35.5029H7.63307V34.1497Z'
					fill='#4F8BFF'
				/>
				<path
					d='M19.7405 35.2999L17.1055 32.6374L19.7337 29.9139L20.7163 30.8511L19.0027 32.6272L20.7095 34.3493L19.7405 35.2999Z'
					fill='#4F8BFF'
				/>
				<path
					d='M26.1325 35.2932L25.1533 34.356L26.8669 32.5832L25.1567 30.8578L26.1257 29.9072L28.7641 32.5697L26.1325 35.2932Z'
					fill='#4F8BFF'
				/>
				<path
					d='M23.5174 29.2858L21.0183 35.615L22.2841 36.1099L24.7832 29.7807L23.5174 29.2858Z'
					fill='#4F8BFF'
				/>
			</g>
			<defs>
				<clipPath id='clip0_75_538'>
					<rect
						width='51'
						height='43'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
export default Customization;
