import './i18n';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from 'styled-components';
import {theme} from "./styles/theme";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
} from 'react-router-dom';

Sentry.init({
  dsn: "https://152c8843f2c44560b855986eab1f111f@sentry.it-aces.com/13",
  beforeSend(event, hint) {
    if (process.env.NODE_ENV === "production") {
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    }
  },
  beforeSendTransaction(event, hint) {
    if (process.env.NODE_ENV !== "production") {
      return event;
    }
  },
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        'localhost',
        'cubekit.com',
        'cubekit-public-landing.prod.cubekit.com',
        'cubekit-public-landing-develop.cluster.it-aces.com',
        /^\//,
      ],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: 1.0,
  release: process.env.REACT_APP_RELEASE_TAG,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();