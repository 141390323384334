import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';
import axios from 'axios';

class General {
	constructor() {
		makeAutoObservable(this);
	}

	/**
	 * Список загруженных языковых словарей
	 */
	loadedLanguages: string[] = [];

	/**
	 * Флаг - идет первичная загрузка словарей
	 */
	loadingDictionary = true;

	/**
	 * Метод загрузки языкового словаря
	 */
	loadLaguageDictionary = (lang: string) => {
		return axios.get(`${process.env.REACT_APP_REMOTE_MINIO_URL}/locales/${lang}.json`);
	};

	/**
	 * Функция смены языка
	 */
	changeLanguage = (lang: string, i18next: any) => {
		if (!this.loadedLanguages.includes(lang)) {
			this.loadedLanguages.push(lang);
			return this.loadLaguageDictionary(lang).then((response) => {
				i18next.addResourceBundle(lang, 'translation', response.data);
				i18next.changeLanguage(lang);
			});
		} else {
			i18next.changeLanguage(lang);
			return Promise.resolve();
		}
	};
}

export const GeneralStore = new General();
export const GeneralStoreContext = createContext(GeneralStore);
