import { FC } from 'react';

const ContentExtraction: FC = () => {
	return (
		<svg
			width='50'
			height='50'
			viewBox='0 0 50 50'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_75_439)'>
				<path
					d='M13.4589 20.1106H1.35019C0.991768 20.1096 0.648351 19.9672 0.395243 19.7145C0.142136 19.4617 -1.25424e-06 19.1194 0 18.7625L0 1.34451C0 0.987922 0.142252 0.645941 0.395461 0.393797C0.648671 0.141653 0.992097 0 1.35019 0L9.40477 0L14.8091 5.46006V18.7625C14.8091 19.1194 14.667 19.4617 14.4139 19.7145C14.1608 19.9672 13.8173 20.1096 13.4589 20.1106ZM1.43256 18.684H13.3765V6.06277L8.80309 1.42653H1.43256V18.684Z'
					fill='#4F8BFF'
				/>
				<path
					d='M14.0929 6.55848H9.56598C9.25377 6.55754 8.95462 6.43362 8.73385 6.21378C8.51308 5.99394 8.38864 5.69605 8.3877 5.38515V0.713257H9.82026V5.13194H14.0929V6.55848Z'
					fill='#4F8BFF'
				/>
				<path
					d='M6.42497 5.13196H3.42017V6.55849H6.42497V5.13196Z'
					fill='#4F8BFF'
				/>
				<path
					d='M11.4604 8.46649H3.42017V9.89303H11.4604V8.46649Z'
					fill='#4F8BFF'
				/>
				<path
					d='M11.4604 11.8046H3.42017V13.2311H11.4604V11.8046Z'
					fill='#4F8BFF'
				/>
				<path
					d='M11.4604 15.1391H3.42017V16.5656H11.4604V15.1391Z'
					fill='#4F8BFF'
				/>
				<path
					d='M31.1118 20.1106H19.003C18.6446 20.1096 18.3012 19.9672 18.0481 19.7145C17.795 19.4617 17.6528 19.1194 17.6528 18.7625V1.34451C17.6528 0.987922 17.7951 0.645941 18.0483 0.393797C18.3015 0.141653 18.6449 0 19.003 0L27.0576 0L32.4655 5.46006V18.7625C32.4646 19.1197 32.3216 19.4621 32.068 19.7147C31.8143 19.9673 31.4705 20.1096 31.1118 20.1106ZM19.0854 18.684H31.033V6.06277L26.4559 1.42653H19.0854V18.684Z'
					fill='#4F8BFF'
				/>
				<path
					d='M31.749 6.55848H27.2186C26.9064 6.55754 26.6072 6.43362 26.3864 6.21378C26.1657 5.99394 26.0412 5.69605 26.0403 5.38515V0.713257H27.4728V5.13194H31.749V6.55848Z'
					fill='#4F8BFF'
				/>
				<path
					d='M24.0778 5.13196H21.073V6.55849H24.0778V5.13196Z'
					fill='#4F8BFF'
				/>
				<path
					d='M29.1133 8.46649H21.073V9.89303H29.1133V8.46649Z'
					fill='#4F8BFF'
				/>
				<path
					d='M29.1133 11.8046H21.073V13.2311H29.1133V11.8046Z'
					fill='#4F8BFF'
				/>
				<path
					d='M22.527 15.1391H21.073V16.5656H22.527V15.1391Z'
					fill='#4F8BFF'
				/>
				<path
					d='M25.8183 15.1391H24.3643V16.5656H25.8183V15.1391Z'
					fill='#4F8BFF'
				/>
				<path
					d='M29.1132 15.1391H27.6592V16.5656H29.1132V15.1391Z'
					fill='#4F8BFF'
				/>
				<path
					d='M48.6498 20.1106H36.5304C36.1719 20.1096 35.8285 19.9672 35.5754 19.7145C35.3223 19.4617 35.1802 19.1194 35.1802 18.7625V1.34451C35.1802 0.987922 35.3224 0.645941 35.5756 0.393797C35.8288 0.141653 36.1723 0 36.5304 0L44.5814 0L49.9893 5.46006V18.7625C49.9893 19.1176 49.8486 19.4583 49.5979 19.7107C49.3471 19.9631 49.0064 20.1068 48.6498 20.1106ZM36.6235 18.684H48.5675V6.06277L43.994 1.42653H36.6235V18.684Z'
					fill='#4F8BFF'
				/>
				<path
					d='M49.2838 6.55848H44.7676C44.4551 6.55848 44.1554 6.43486 43.9345 6.21482C43.7135 5.99478 43.5894 5.69634 43.5894 5.38515V0.713257H45.0219V5.13194H49.2945L49.2838 6.55848Z'
					fill='#4F8BFF'
				/>
				<path
					d='M41.6159 5.13196H38.6111V6.55849H41.6159V5.13196Z'
					fill='#4F8BFF'
				/>
				<path
					d='M46.6513 8.46649H38.6111V9.89303H46.6513V8.46649Z'
					fill='#4F8BFF'
				/>
				<path
					d='M46.6513 11.8046H38.6111V13.2311H46.6513V11.8046Z'
					fill='#4F8BFF'
				/>
				<path
					d='M46.6513 15.1391H38.6111V16.5656H46.6513V15.1391Z'
					fill='#4F8BFF'
				/>
				<path
					d='M17.3771 29.7717H15.9445V26.9793C15.9445 26.2226 15.6427 25.4969 15.1054 24.9619C14.568 24.4268 13.8393 24.1262 13.0794 24.1262H11.0094C9.86954 24.1262 8.77641 23.6754 7.97043 22.8728C7.16446 22.0702 6.71167 20.9817 6.71167 19.8466V19.3973H8.14423V19.868C8.14423 20.6247 8.44609 21.3504 8.98341 21.8855C9.52072 22.4205 10.2495 22.7211 11.0094 22.7211H13.0902C13.6545 22.7211 14.2134 22.8318 14.7348 23.0469C15.2562 23.2619 15.73 23.5772 16.1291 23.9746C16.5282 24.372 16.8447 24.8437 17.0607 25.363C17.2767 25.8822 17.3878 26.4387 17.3878 27.0007L17.3771 29.7717Z'
					fill='#4F8BFF'
				/>
				<path
					d='M16.6248 30.6455C16.4813 30.644 16.3395 30.6139 16.2079 30.5569C16.0762 30.4999 15.9574 30.4173 15.8584 30.3138L12.9753 27.4608L13.9889 26.4515L16.6248 29.0763L19.2965 26.4158L20.3065 27.4251L17.3912 30.3138C17.2922 30.4173 17.1733 30.4999 17.0417 30.5569C16.9101 30.6139 16.7683 30.644 16.6248 30.6455Z'
					fill='#4F8BFF'
				/>
				<path
					d='M34.1271 29.7717H32.6946V26.9793C32.6946 25.8443 33.1474 24.7557 33.9533 23.9532C34.7593 23.1506 35.8524 22.6997 36.9923 22.6997H39.0695C39.8294 22.6997 40.5581 22.3991 41.0954 21.8641C41.6327 21.329 41.9346 20.6033 41.9346 19.8466V19.3973H43.3672V19.868C43.3672 21.0031 42.9144 22.0916 42.1084 22.8942C41.3024 23.6967 40.2093 24.1476 39.0695 24.1476H36.9923C36.2324 24.1476 35.5036 24.4482 34.9663 24.9833C34.429 25.5183 34.1271 26.244 34.1271 27.0007V29.7717Z'
					fill='#4F8BFF'
				/>
				<path
					d='M33.4467 30.6455C33.3045 30.6463 33.1635 30.619 33.0319 30.5651C32.9004 30.5113 32.7808 30.4319 32.6803 30.3317L29.7615 27.4251L30.775 26.4158L33.4467 29.0763L36.0827 26.4515L37.0962 27.4608L34.2311 30.3138C34.13 30.4195 34.0082 30.5034 33.8733 30.5604C33.7385 30.6174 33.5933 30.6464 33.4467 30.6455Z'
					fill='#4F8BFF'
				/>
				<path
					d='M25.0698 38.9051C24.9275 38.906 24.7865 38.8786 24.655 38.8248C24.5234 38.7709 24.4039 38.6915 24.3034 38.5913L21.3845 35.6847L22.3981 34.6755L25.0698 37.336L27.7057 34.7111L28.7157 35.7204L25.8505 38.5735C25.7491 38.6777 25.6278 38.7607 25.4937 38.8176C25.3597 38.8746 25.2155 38.9043 25.0698 38.9051Z'
					fill='#4F8BFF'
				/>
				<path
					d='M25.7753 19.3973H24.3428V37.8138H25.7753V19.3973Z'
					fill='#4F8BFF'
				/>
				<path
					d='M25.0699 50C24.9484 50.0007 24.8276 49.9827 24.7117 49.9465L5.12146 44.1655C4.86647 44.0879 4.64308 43.9311 4.48393 43.7181C4.32477 43.5051 4.2382 43.247 4.23685 42.9815V37.3039C4.23484 37.0395 4.3189 36.7816 4.47644 36.5688C4.63398 36.356 4.85651 36.1997 5.11071 36.1234L24.7117 30.1391C24.9451 30.0678 25.1946 30.0678 25.428 30.1391L44.8536 35.8952C45.1095 35.9716 45.334 36.128 45.4939 36.3412C45.6539 36.5544 45.7407 36.8131 45.7418 37.0792V42.985C45.7404 43.2505 45.6538 43.5087 45.4947 43.7217C45.3355 43.9347 45.1121 44.0915 44.8572 44.1691L25.428 49.9465C25.312 49.982 25.1913 50 25.0699 50ZM5.66225 42.8388L25.0591 48.5449L44.2949 42.8388V37.2183L25.0699 31.5193L5.66225 37.4465V42.8388Z'
					fill='#4F8BFF'
				/>
				<path
					d='M24.9804 43.1812L5.11792 37.4893L5.51187 36.1198L24.9768 41.6976L44.6351 36.0021L45.0327 37.3716L24.9804 43.1812Z'
					fill='#4F8BFF'
				/>
				<path
					d='M25.6931 42.4394H24.2605V49.2618H25.6931V42.4394Z'
					fill='#4F8BFF'
				/>
			</g>
			<defs>
				<clipPath id='clip0_75_439'>
					<rect
						width='50'
						height='50'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default ContentExtraction;
