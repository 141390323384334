export const theme = {

  fonts: {
    mainRegular: 'Inter-Regular, Arial, sans-serif',
    mainMedium: 'Inter-Medium, Arial, sans-serif',
    mainBold: 'Inter-Bold, Arial, sans-serif',
  },

  colors: {
    primary: "#6385C7",
  }
}